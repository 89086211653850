import {combineReducers, createStore, applyMiddleware, Store, Middleware, AnyAction} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import {sharedStoreMiddleware} from "redux-socket-client";
import {
    liveopsSaga, conversationsReducer as conversations, visitorsReducer as visitors, agentsReducer as agents,
    readyReducer as ready, historyReducer as history, createUserReducer, HistoryState, ReadyState,
    sessionsReducer as visitor, VisitorSessionsState, notificationsReducer as notifications, NotificationsState
} from "./liveops";
import {User, FetchedConversation, IGrant} from "../types";
import {Api} from "../services/Api";
import {WidgetState, widgetReducer as widget, ConnectedState, connectedReducer as connected} from "./local";
import {initLocalisation} from "../services/localisation";

export interface StoreState {
    history: HistoryState
    conversations: FetchedConversation[]
    visitors: User[]
    visitor: VisitorSessionsState
    agents: User[]
    user: User
    grants: IGrant[]
    ready: ReadyState
    widget: WidgetState
    connected: ConnectedState
    notifications: NotificationsState
}

type Listener = (action: AnyAction) => void;
const listeners: Listener[] = [];
const injectedListenerMiddleware: Middleware = () => (next) => (action) => {
    next(action);
    listeners.forEach(listener => listener(action))
};

let store_: Store<StoreState>;
export const getStore = () => store_;
export const registerStoreListener = (listener: Listener) => listeners.push(listener);

export const createGrantsReducer = (grants: IGrant[]) => {
    return (state = grants) => state
};

export const initStore = async () => {
    const sagaMiddleware = (createSagaMiddleware as any)();
    const { locale, ...user } = await Api.getMe();
    const grants = await Api.getGrants();
    await initLocalisation(locale)

    const reducer = combineReducers({
        history,
        conversations,
        visitors,
        visitor,
        agents,
        user: createUserReducer(user),
        grants: createGrantsReducer(grants),
        ready,
        widget,
        connected,
        notifications

    });

    store_ = createStore(
        reducer,
        composeWithDevTools(applyMiddleware(sharedStoreMiddleware( { clientFirst: false }), sagaMiddleware, injectedListenerMiddleware))
    );

    function* rootSaga() {
        yield all([fork(liveopsSaga)])
    }

    sagaMiddleware.run(rootSaga);

    return store_
};
