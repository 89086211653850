import { Reducer } from 'redux';
import {AGENT_NOTIFICATIONS} from "../constants";

export interface NotificationsState {
    enabled: boolean
}

export const notificationsReducer: Reducer = (state = { enabled: false }, { type, payload = {} }) => {
    switch(type) {
        case AGENT_NOTIFICATIONS:
            return { ...state, enabled: payload.enabled };

        default:
            return state
    }
};