import { Reducer } from 'redux';
import {USER_CONNECTED, USER_DISCONNECTED} from "../constants";

export interface ConnectedState {
    connected: boolean
}

const DEFAULT_STATE = {
    connected: false,
};

export const connectedReducer: Reducer = (state = DEFAULT_STATE, { type, payload }) => {
    switch(type) {
        case USER_CONNECTED: {
            return { connected: true };
        }
        case USER_DISCONNECTED: {
            return { connected: false };
        }
        default:
            return state
    }
};
