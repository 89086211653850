import * as React from 'react';
import { useInView } from 'react-intersection-observer'
import "./InfiniteScroller.scss";

const useInfiniteScroll = (done: boolean, callback: () => void) => {
    const [ref, inView] = useInView();

    React.useEffect(() => {
        if (inView && !done) callback()
    }, [inView, done, callback]);

    return ref
};

interface Props {
    data: any[],
    page: number
    pageSize: number
    reachedEnd: boolean
    loadPage: (page: number) => void
    className?: string
}

export const InfiniteScroller: React.FC<Props> = ({ data, page, pageSize, reachedEnd, loadPage, children, className = '' }) => {
    const infiniteScrollRef = useInfiniteScroll(
        (data.length < page * pageSize) || reachedEnd,
        React.useCallback(() => loadPage(page + 1),[ page, loadPage ])
    );

    return (
        <div className={`infinite-scroller ${className}`}>
            {children}
            <div style={{height: 1 }} ref={infiniteScrollRef} />
        </div>
    )
};
