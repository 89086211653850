import { Reducer } from 'redux';
import {PRESENT} from "redux-socket-client";
import {
    ADD_TO_HISTORY_FETCHED,
    END_HISTORY, LOAD_HISTORY
} from "../constants";
import {FetchedConversation} from "../../../types";

export interface HistoryState {
    items: FetchedConversation[]
    complete: boolean
    lastPage: number
}

export const historyReducer: Reducer = (state: HistoryState = { items: [], complete: false, lastPage: 0 }, { type, payload = {} }) => {
    switch(type) {
        case LOAD_HISTORY:
            return {
                ...state,
                lastPage: payload.page
            };
        case ADD_TO_HISTORY_FETCHED:
            if(state.items.find(c => c.id === payload.conversation.id)) return state;
            return {
                ...state,
                items: payload.index
                    ? [ ...state.items.slice(0, payload.index), payload.conversation, ...state.items.slice(payload.index) ]
                    : [ payload.conversation, ...state.items ]
            };
        case END_HISTORY:
        case PRESENT:
        default:
            return state
    }
};