import * as React from 'react'
import {WidgetBridge} from "./chat-widget-lib/WidgetBridge";
import {Api} from "../../services/Api";
import { LogiscoolSupportWidget } from "./chat-widget-lib";
import "./TestPage.scss";
import {WidgetBridgeNative} from "./chat-widget-lib/WidgetBridgeNative";

interface Props {
    native?: boolean
}

const TestPage: React.FC<Props> = ({ native }) => {
    const [ bridge, setBridge ] = React.useState<WidgetBridge|WidgetBridgeNative>();
    const [ title, setTitle ] = React.useState('Test Page');
    const [ path, setPath ] = React.useState('http://www.example.com');

    return (
        <div className="test-page">
            {bridge && <div className="tester">
                <button onClick={bridge.button.show}>Show Button</button>
                <button onClick={bridge.button.hide}>Hide Button</button>
                <button onClick={bridge.widget.open}>Open Widget</button>
                <button onClick={bridge.widget.close}>Close Widget</button>
                <input value={title} onChange={e => setTitle(e.target.value)} />
                <input value={path} onChange={e => setPath(e.target.value)} />
                <button onClick={() => bridge.visitor.sendPath({ title, path })}>Send Visitor Path</button>
            </div>}
            <LogiscoolSupportWidget accessToken={Api.token}
                                    basePath={window.location.origin}
                                    setBridge={setBridge}
                                    showButtonOnLoad={true}
                                    native={native} />
        </div>
    )
};

export default TestPage;