import * as React from 'react';
import ReactCountryFlag from "react-country-flag";
import "./LocaleFlag.scss";

interface Props {
    country: string
}

export const LocaleFlag: React.FC<Props> = ({country}) => {
    if(!country) return null;
    return (
        <span className="flag" aria-label={country} data-balloon-pos="up">
            <ReactCountryFlag code={country} svg
                              styleProps={{
                                  width: '14px',
                                  height: '12px'
                              }} />
        </span>
    )
};