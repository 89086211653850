import {EventEmitter} from "events";
import {NativeBrowserBridge} from "./NativeBrowserBridge";

interface VisitorPathEntry {
    title: string,
    path: string
}

type Position = { bottom: number, right: number };

function getPath() {
    return {
        title: document.title,
        path: document.location.href
    }
}

const CLASS = 'logiscool-support-chat-widget';

export class WidgetBridgeNative extends EventEmitter {

    private bridge: any = null as any;

    private buttonPosition = { right: 20, bottom: 60 };
    private widgetPosition = { right: 10, bottom: 50 };
    private lastPath: VisitorPathEntry = { title: '', path: '' };
    
    private setPath(path: VisitorPathEntry) {
        if(this.lastPath.path === path.path && this.lastPath.title === path.title) return false;
        this.lastPath = path;
        return true
    }

    private update: () => void = () => {};

    constructor(container: HTMLElement|null, token: string, origin: string) {
        super();

        if(container) {
            const widget = document.createElement('iframe');
            container.appendChild(widget);
            widget.className = CLASS;

            widget.onload = async () => {
                const bridge = this.bridge = NativeBrowserBridge.getInstance();
                await this.bridge.init(widget.contentWindow, origin);
                this.emit('ready');

                const showAs = (kind: 'btn' | 'open', position: Position) => {
                    widget.className = `${CLASS} ${kind}`;
                    widget.style.right = `${position.right}px`;
                    widget.style.bottom = `${position.bottom}px`
                };

                const hide = () => widget.className = CLASS;

                let buttonVisible = await bridge.request('buttonVisible');
                let widgetOpen = await bridge.request('widgetOpen');

                this.update = () => {
                    if (widgetOpen) showAs('open', this.widgetPosition);
                    else if (buttonVisible) showAs('btn', this.buttonPosition);
                    else hide()
                };

                this.update();

                bridge.on('unread-message', () => {
                    this.emit('unread-message')
                });

                bridge.on('open-widget', () => {
                    widgetOpen = true;
                    showAs('open', this.widgetPosition);
                    this.emit('widget-open')
                });

                bridge.on('close-widget', () => {
                    widgetOpen = false;
                    if (buttonVisible) showAs('btn', this.buttonPosition);
                    else hide();
                    this.emit('widget-close')
                });

                bridge.on('show-button', () => {
                    buttonVisible = true;
                    if (!widgetOpen) showAs('btn', this.buttonPosition)
                });

                bridge.on('hide-button', () => {
                    buttonVisible = false;
                    if (!widgetOpen) hide()
                })
            };

            let attempt = 1;

            widget.onerror = () => {
                if(attempt > 3) {
                    this.emit('error')
                }
                else {
                    attempt++;
                    widget.src = `${origin}/widget?access_token=${token}`
                }
            };

            widget.src = `${origin}/widget?access_token=${token}`
        }
    }

    widget = {
        onReady: (handler: Function) => this.bridge && this.bridge.on('ready', handler),
        open: () => this.bridge && this.bridge.emitRemote('openWidget'),
        close: () => this.bridge && this.bridge.emitRemote('closeWidget'),
        setPosition: (position: Position) => {
            this.widgetPosition = position;
            this.update()
        },
        setCompileResult: (result: any) => this.bridge && this.bridge.emitRemote('setCompileResult', { compileResult: result })
    };

    button = {
        show: () => this.bridge && this.bridge.emitRemote('showButton'),
        hide: () => this.bridge && this.bridge.emitRemote('hideButton'),
        setPosition: (position: Position) => {
            this.buttonPosition = position;
            this.update()
        }
    };

    visitor = {
        sendPath: (path?: VisitorPathEntry) => {
            if(this.bridge) {
                if(this.setPath(path || getPath())) {
                    this.bridge.emitRemote('sendVisitorPath', { path: this.lastPath })
                }
            }
        }
    }
}