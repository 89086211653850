import * as React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { OauthReceiver } from '../oauth-receiver/OauthReceiver'
import { OauthSender } from '../oauth-sender/OauthSender';
import {LoadingPage} from "../../../pages/loading/LoadingPage";

interface Props {
    onSuccess: (token: string, from: string) => void
}

export const AuthFlow: React.FC<Props> = props => {
    const origin = encodeURIComponent(
        window.location.pathname + window.location.search
    );

    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path="/auth/scoolcode"
                    render={() => (
                        <OauthReceiver
                            onSuccess={props.onSuccess}
                            render={({ hasError }) => {
                                if (hasError) {
                                    return (
                                        <span>Something went wrong</span>
                                    )
                                }
                                return <LoadingPage />
                            }}
                        />
                    )}
                />
                <Route
                    path="/"
                    render={() => (
                        <OauthSender
                            redirectUri={`${
                                window.location.origin
                            }/auth/scoolcode`}
                            origin={origin}
                            render={url => {
                                window.location.assign(url);
                                return <LoadingPage />
                            }}
                        />
                    )}
                />
            </Switch>
        </BrowserRouter>
    )
}
