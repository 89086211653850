import * as React from 'react';
import {StoreState} from "../../store/store";
import {connect} from "react-redux";
import {FetchedConversation, User, VisitorInfo, VisitorSession} from "../../types";
import {subscribeToVisitor, unsubscribeFromVisitor} from "../../store/liveops/actions";
import {MdAccountCircle, MdWork} from 'react-icons/md';
import "./VisitorInfo.scss";
import {UserAgentIcon} from "./elements/UserAgentIcon";
import {asFriendlyTime} from "../../helpers/selectors";
import {LocaleFlag} from "..";
import {VisitorPath} from "./elements/VisitorPath";

interface Props {
    conversation?: FetchedConversation
    visitor?: string
    className?: string
}

interface StateProps {
    visitors: User[]
    visitorInfo: VisitorInfo|null
    sessions: VisitorSession[]
}

interface DispatchProps {
    subscribeToVisitor: typeof subscribeToVisitor
    unsubscribeFromVisitor: typeof unsubscribeFromVisitor
}

type AllProps = Props & StateProps & DispatchProps

const VisitorInfoPane: React.FC<AllProps> = ({ conversation: conv, visitor: id, visitors, visitorInfo, subscribeToVisitor, unsubscribeFromVisitor, className, sessions }) => {
    const conversation = conv || { visitor: visitors.find(visitor => visitor.id === id) as any, agent: null, department: '' };
    const visitorId = conv ? conv.visitor.id : id;

    React.useEffect(() => {
        if(visitorId) subscribeToVisitor(visitorId);
        return () => {
            if (visitorId) unsubscribeFromVisitor(visitorId)
        }
    }, [ visitorId, subscribeToVisitor, unsubscribeFromVisitor ]);

    if(!conversation.visitor) return null;
    const visitor = visitors.find(user => conversation.visitor.id === user.id) || visitorInfo;
    sessions = sessions.filter(s => s.visitorInfo);
    return (
        <div className={`visitor-info-pane ${className || ''}`}>
            <div className="visitor">
                {!visitor && <h1>Visitor</h1>}
                {visitor && <h1>{visitor.fullName}</h1>}
                {(visitor && visitor.email) && <p className="email">{visitor.email}</p>}
                <p className="username">
                    {visitorInfo && <LocaleFlag country={visitorInfo.country}/>}
                    <span>{conversation.visitor.username}</span>
                </p>
                {visitorInfo && <p className="department">
                    <span className="icon"><MdAccountCircle /></span>
                    <span>{visitorInfo.department}</span>
                </p>}
            </div>

            {conversation.agent && <div className="agent">
                <span className="icon"><MdWork /></span>
                <span>assigned to <strong>{conversation.agent.username}</strong></span>
            </div>}

            {!!sessions.length && <h4>Sessions</h4>}
            {sessions.map((({ session, visitorInfo }) => (
                <section className="session" key={session}>
                    <div className="icons">
                        <UserAgentIcon kind='device' entry={visitorInfo} />
                        <UserAgentIcon kind='OS' entry={visitorInfo} />
                        <UserAgentIcon kind='browser' entry={visitorInfo} />
                        <span className="timestamp">{asFriendlyTime(visitorInfo.createdAt)}</span>
                    </div>
                    <VisitorPath path={visitorInfo.visitorPath}/>
                </section>
            )))}
        </div>
    )
};

const mapStateToProps = (state: StoreState) => {
    return {
        visitors: state.visitors,
        visitorInfo: state.visitor.info || null,
        sessions: state.visitor.sessions
    }
};

const mapDispatchToProps = {
    subscribeToVisitor,
    unsubscribeFromVisitor
};

const ConnectedVisitorInfo = connect<
    StateProps,
    DispatchProps,
    {},
    StoreState
    >(
    mapStateToProps,
    mapDispatchToProps
)(VisitorInfoPane);

export { ConnectedVisitorInfo as VisitorInfoPane };