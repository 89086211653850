import { fork, all, takeEvery, put, select } from 'redux-saga/effects'
import {sendEndConversation /*, leaveConversation*/} from "./actions";
import {
    CHAT_MESSAGE,
    CONVERSATION_FETCHED,
    CONVERSATION,
    CHAT_MESSAGE_FETCHED,
    SERVICE_MESSAGE,
    SERVICE_MESSAGE_FETCHED,
//    END_CONVERSATION,
    READ_RECEIPT_FETCHED,
    READ_RECEIPT,
    SEND_CHAT_MESSAGE,
    SEND_SERVICE_MESSAGE,
    BEGIN_TYPING,
    BEGIN_TYPING_FETCHED,
    CONVERSATION_ASSIGNED,
    CONVERSATION_ASSIGNED_FETCHED,
    ADD_TO_HISTORY,
    ADD_TO_HISTORY_FETCHED,
    END_MY_CONVERSATION
} from "./constants";
import { FetchedChatMessage, FetchedConversation, MinimalUser } from "../../types";
import {StoreState} from "../store";
import {canTake, canWrite} from "../../helpers/selectors";
import {playSoundEffect} from "../../helpers/sound-service";
import {NEW_UNREAD_MESSAGE} from "../local";

function getUser(state: StoreState) { return state.user }
function getConversation(state: StoreState, id: string) { return state.conversations.find(c => c.id === id) }
function getWidgetOpen(state: StoreState) { return state.widget.open }

function* watchConversation() {
    yield takeEvery(
        CONVERSATION,
        function* (action: any) {
            yield put({
                type: CONVERSATION_FETCHED,
                payload: action.payload
            })
        }
    )
}

function* watchAddToHistory() {
    yield takeEvery(
        ADD_TO_HISTORY,
        function* (action: any) {
            yield put({
                type: ADD_TO_HISTORY_FETCHED,
                payload: action.payload
            })
        }
    )
}

/*
function* watchEndConversation() {
    yield takeEvery(
        END_CONVERSATION,
        function* (action: any) {
            yield put(leaveConversation(action.payload.conversation))
        }
    )
}*/

function* watchEndMyConversation() {
    yield takeEvery(
        END_MY_CONVERSATION,
        function* (action: any) {
            yield put(sendEndConversation(action.payload.conversation, action.payload.resolution));
            playSoundEffect('end-chat')
        }
    )
}

function* watchSendChatMessages() {
    yield takeEvery(
        SEND_CHAT_MESSAGE,
        function* (action: any) {
            const { conversation, message, cacheId } = action.payload;
            let from = yield select(getUser);
            yield put({
                type: CHAT_MESSAGE_FETCHED,
                payload: { conversation, message, cacheId, from, delivered: false }
            })
        }
    )
}

function* watchChatMessages() {
    yield takeEvery(
        CHAT_MESSAGE,
        function* (action: any) {
            const payload = action.payload as FetchedChatMessage;

            if(window.location.pathname !== `/agent/chat/${payload.conversation}`) {
                let isWidgetOpen: boolean = yield select(getWidgetOpen);
                if(window.location.pathname !== '/widget' || !isWidgetOpen) {
                    let self: MinimalUser = yield select(getUser);
                    let conversation: FetchedConversation = yield select(getConversation, payload.conversation);
                    if (payload.from.id !== self.id && (canWrite(conversation, self) || canTake(conversation))) {
                        playSoundEffect('new-message', 2000, payload.conversation);
                        const noti = new Notification(`${payload.from.username}:`, { 
                            body: '» ' + (payload.message || ''),
                            icon: '/favicon.ico',
                            data: payload.conversation
                        });
                        noti.onclick = (e:any) => {
                            const conversation = e && e.target && e.target.data;
                            if (conversation)
                                window.dispatchEvent(new CustomEvent("goThere", { detail: `/agent/chat/${conversation}` }));
                        };
                        yield put({ type: NEW_UNREAD_MESSAGE })
                    }
                }
            }

            yield put({
                type: CHAT_MESSAGE_FETCHED,
                payload: { ...payload, delivered: true }
            })
        }
    )
}

function* watchSendServiceMessages() {
    yield takeEvery(
        SEND_SERVICE_MESSAGE,
        function* (action: any) {
            const { conversation, kind, data, cacheId } = action.payload;
            let from = yield select(getUser);
            yield put({
                type: SERVICE_MESSAGE_FETCHED,
                payload: { conversation, kind, data, cacheId, from, delivered: false }
            })
        }
    )
}

function* watchServiceMessages() {
    yield takeEvery(
        SERVICE_MESSAGE,
        function* (action: any) {
            yield put({
                type: SERVICE_MESSAGE_FETCHED,
                payload: { ...action.payload, delivered: true }
            })
        }
    )
}

function* watchReadReceipts() {
    yield takeEvery(
        READ_RECEIPT,
        function* (action: any) {
            yield put({
                type: READ_RECEIPT_FETCHED,
                payload: action.payload
            })
        }
    )
}

function* watchBeginTypings() {
    yield takeEvery(
        BEGIN_TYPING,
        function* (action: any) {
            let self = yield select(getUser);
            if(action.payload.user.id !== self.id) {
                yield put({
                    type: BEGIN_TYPING_FETCHED,
                    payload: action.payload
                })
            }
        }
    )
}

function* watchConversationAssigned() {
    yield takeEvery(
        CONVERSATION_ASSIGNED,
        function* (action: any) {
            yield put({
                type: CONVERSATION_ASSIGNED_FETCHED,
                payload: action.payload
            })
        }
    )
}

export function* liveopsSaga() {
    yield all([
        fork(watchAddToHistory),
        fork(watchConversation),
//        fork(watchEndConversation),
        fork(watchEndMyConversation),
        fork(watchSendChatMessages),
        fork(watchSendServiceMessages),
        fork(watchChatMessages),
        fork(watchServiceMessages),
        fork(watchReadReceipts),
        fork(watchBeginTypings),
        fork(watchConversationAssigned)
    ])
}
