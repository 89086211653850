import * as React from 'react';
import "./Loader.scss";

export const Loader: React.FC = () => {
    return (
        <div className="loader">
            <span className="loading-indicator">L O A D I N G</span>
        </div>
    )
};
