import * as React from 'react'
import {WidgetBridge} from "./WidgetBridge";
import "./ChatWidget.scss";
import {WidgetBridgeNative} from "./WidgetBridgeNative";

interface Params {
    basePath: string
    accessToken: string
    setBridge?: (bridge: WidgetBridge|WidgetBridgeNative) => void;
    showButtonOnLoad?: boolean
    openOnLoad?: boolean
    native?: boolean
}

declare global {
    interface History { onpushstate?: (data: any) => void; }
}

//Based on https://stackoverflow.com/a/12709880
((history) => {
    const pushState = history.pushState;
    history.pushState = function(state) {
        if (history.onpushstate) history.onpushstate(state);
        return pushState.apply(history, arguments as any)
    }
})(window.history);

export const LogiscoolSupportWidget: React.FC<Params> = ({ native, accessToken, setBridge, basePath, showButtonOnLoad, openOnLoad }) => {
    const widgetRef = React.useRef(null);

    const setupBridge = React.useCallback((bridge: WidgetBridge|WidgetBridgeNative) => {
        window.history.onpushstate = () => bridge.visitor.sendPath();
        if(setBridge) setBridge(bridge);
        if(showButtonOnLoad) bridge.button.show();
        if(openOnLoad) bridge.widget.open()
    }, [ setBridge, showButtonOnLoad, openOnLoad ]);

    React.useEffect(() => {
        if(widgetRef.current) {
            const Bridge = native ? WidgetBridgeNative : WidgetBridge;
            const bridge = new Bridge(widgetRef.current, accessToken, basePath);
            bridge.on('ready', () => setupBridge(bridge))
        }
    }, [ accessToken, basePath, setupBridge, native ]);

    return <div ref={widgetRef} />
};