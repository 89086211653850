import * as React from 'react';
import "./MessageInput.scss";
import {useTranslation} from 'react-i18next'

interface Props {
    conversationId: string
    departmentId: string
    sendBeginTyping: (conversation: string, department:string) => void
    sendEndTyping: (conversation: string, department:string) => void
    onSend: (message: string) => void
    onSendImage: (data: string) => void
}

export const MessageInput: React.FC<Props> = props => {
    const {t} = useTranslation()
    const [ timer, setTimer ] = React.useState<NodeJS.Timeout|null>(null);
    const [ currentConversationId, setCurrentConversationId ] = React.useState(props.conversationId);
    React.useEffect(() => {
        if(currentConversationId !== props.conversationId) {
            endTyping();
            setCurrentConversationId(props.conversationId)
        }
        // eslint-disable-next-line
    }, [props.conversationId, currentConversationId, setCurrentConversationId ]);


    function handleTyping() {
        if(timer) clearTimeout(timer);
        else props.sendBeginTyping(props.conversationId, props.departmentId);
        setTimer(setTimeout(endTyping, 1500))
    }

    function endTyping() {
        if(timer) clearTimeout(timer);
        setTimer(null);
        props.sendEndTyping(currentConversationId, props.departmentId)
    }

    function handleSend() {
        const text = inputRef.current ? inputRef.current.innerText.trim() : '';
        if(text) {
            if (timer) clearTimeout(timer);
            setTimer(setTimeout(endTyping, 500));
            props.onSend(text);
            if(inputRef.current) inputRef.current.innerText = ''
        }
    }

    const inputRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className="message-input">
            <div className="input" contentEditable={true} ref={inputRef}
                placeholder={t('typeMessage')}
                onPaste={event => {
                    event.preventDefault();
                    // TODO kepkuldest kikapcsoltam, mert meg tul sok minden hianyzik belole
                    if (false) { // (event.clipboardData.files.length) {
                        for (let j = 0; j < event.clipboardData.files.length; j++) {
                            // find first image
                            if (event.clipboardData.files[j].type.indexOf("image/") > -1) {
                                var reader = new FileReader();
                                // eslint-disable-next-line
                                reader.onload = function(event) {
                                    props.onSendImage(reader.result as string);
                                };
                                reader.readAsDataURL(event.clipboardData.files[j]);
                                console.log(`sending "${event.clipboardData.files[j].name}" ${event.clipboardData.files[j].size} bytes`);
                                break;
                            }
                        }
                    }
                    const text = event.clipboardData.getData("text");
                    document.execCommand('insertText', false, text)
                }}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSend()
                    }
                    else handleTyping()
                }} />
        </div>
    )
};
