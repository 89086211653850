import {FetchedChatMessage, FetchedConversation, MinimalUser} from "../types";
import moment from "moment";

export function getLastMessage(conversation: FetchedConversation): FetchedChatMessage|{ message: string, createdAt: null, from: { id: string} } {
    const filteredMessages = conversation.messages.filter(m => !m.kind);
    const lastMsg = filteredMessages[filteredMessages.length - 1];
    return lastMsg ? lastMsg : { message: '', createdAt: null, from: { id: '' } }
}

export function getConversationDate(conversation: FetchedConversation): Date {
    const filteredMessages = conversation.messages.filter(m => m.message);
    let lastMsgDate = filteredMessages.length ? filteredMessages[filteredMessages.length - 1].createdAt : null;
    if (!lastMsgDate)
    {
        lastMsgDate = conversation.messages.length ? conversation.messages[conversation.messages.length - 1].createdAt : null;
        if (!lastMsgDate)
            if (conversation.endedAt)
                lastMsgDate = conversation.endedAt;
            else
                lastMsgDate = conversation.createdAt;
    }
    return new Date(lastMsgDate);
}

export function isUnread(conversation: FetchedConversation, self: MinimalUser) {
    if(!conversation.agent) return true;
    if(conversation.agent.id !== self.id && !conversation.joined) return false;
    const filteredMessages = conversation.messages.filter(m => !m.kind);
    const lastMsg = filteredMessages[filteredMessages.length - 1];
    if(!lastMsg) return false;
    if(lastMsg.from.id === self.id) return false;
    return !conversation.readReceipts
        .find(rr => (!rr.user || rr.user.id === self.id) && rr.message === lastMsg.id)
}

export function isUnreadByVisitor(conversation: FetchedConversation, self: MinimalUser) {
    if(!conversation.joined) return false;
    const filteredMessages = conversation.messages.filter(m => !m.kind);
    const lastMsg = filteredMessages[filteredMessages.length - 1];
    if(!lastMsg) return false;
    if(lastMsg.from.id === self.id) return false;
    return !conversation.readReceipts
        .find(rr => (!rr.user || rr.user.id === self.id) && rr.message === lastMsg.id)
}

export function canEnd(conversation: FetchedConversation, self: MinimalUser) {
    if(!conversation.agent) return true;
    return conversation.agent.id === self.id
}

export function canWrite(conversation: FetchedConversation, self: MinimalUser) {
    return conversation.joined || (conversation.agent && conversation.agent.id === self.id)
}

export function canTake(conversation: FetchedConversation) {
    if(!conversation.agent) return true
}

export function asFriendlyTime(date?: Date) {
    if (date === undefined)
        return '';

    const isThisYear = moment().isSame(date, 'year');

    let format = 'YYYY MMM D. H:mm';
    if(isThisYear) {
        const isToday = moment().isSame(date, 'day');
        const dayDiffNow = moment().diff(date, 'days');

        format = isToday
            ? 'H:mm'
            : ((dayDiffNow > 1) ? 'MMM D. H:mm' : 'ddd H:mm')
    }

    return moment(date).format(format)
}

export function getUrlParameter(name: string) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}