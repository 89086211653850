import * as React from 'react';
import {VisitorPathEntry} from "../../../types";
import {Button} from "../..";
import {MdChevronRight, MdExpandMore} from "react-icons/md";

interface Props {
    path: VisitorPathEntry[]
}

export const VisitorPath: React.FC<Props> = ({ path }) => {
    const [ expanded, setExpanded ] = React.useState(false);
    if (!Array.isArray(path) || path.length < 1)
        return null;
    const [ first, ...remaining ] = path;

    return (
        <div className="path">
            <div className="path-entry">
                {!!remaining.length && <Button icon={expanded ? MdExpandMore : MdChevronRight} round
                                             onClick={() => setExpanded(!expanded)} />}
                <span aria-label={first.path.replace(/http(s?):\/\//, '')}
                      data-balloon-pos="up-left" data-balloon-length="medium">
                    <a className="real-link" rel="noopener noreferrer" href={first.path} target="_blank">{first.title}</a>
                </span>
            </div>
            {expanded && <>
                {remaining.map((entry, i) => <div className="path-entry" key={i}>
                    <span aria-label={entry.path.replace(/http(s?):\/\//, '')}
                          data-balloon-pos="up-left" data-balloon-length="medium">
                        <a className="real-link" rel="noopener noreferrer" href={entry.path} target="_blank">{entry.title}</a>
                    </span>
                </div>)}
            </>}
        </div>
    )
};
