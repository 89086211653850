import { Reducer } from 'redux';
import {PRESENT} from "redux-socket-client";
import {USER_ACTIVE, USER_INACTIVE, USER_LIST, AGENT_STATUS} from "../constants";
import {User} from "../../../types";

function sortByPresence(a:User, b:User):number {
    if (a.presence === 'active' && b.presence !== 'active')
        return -1;
    else if (b.presence === 'active')
        return 1;
    else if (b.presence === 'offline' && a.presence !== 'offline')
        return -1;
    else if (a.presence === 'offline')
        return 1;
    return 0;
}

export const agentsReducer: Reducer = (state: User[] = [], { type, payload = {} }) => {
    switch(type) {
        case USER_LIST:
            if(payload.kind === 'agent') return payload.users.sort(sortByPresence);
            return state;

        case USER_ACTIVE:
            if(payload.kind === 'visitor') {
                return state.filter(u => u.id !== payload.user.id)
            }
            else if(!state.find(u => u.id === payload.user.id)) {
                return [
                    ...state,
                    payload.user
                ].sort(sortByPresence)
            }
            else {
                return state
            }

        case USER_INACTIVE:
            return state.filter(u => u.id !== payload.user);
        
        case AGENT_STATUS:
            return state.map(u => (u.id === payload.id ? payload : u)).sort(sortByPresence);

        case PRESENT:
        default:
            return state
    }
};
