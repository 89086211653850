export const CONVERSATION = 'SERVER/CHAT/CONVERSATION';
export const CONVERSATION_FETCHED = 'SERVER/CHAT/CONVERSATION:fetched';
export const NO_CONVERSATION = 'SERVER/CHAT/NO_CONVERSATION';
export const LOAD_HISTORY = 'CLIENT/CHAT/LOAD_HISTORY';
export const END_HISTORY = 'SERVER/CHAT/END_HISTORY';
export const ADD_TO_HISTORY = 'SERVER/CHAT/ADD_TO_HISTORY';
export const ADD_TO_HISTORY_FETCHED = 'SERVER/CHAT/ADD_TO_HISTORY:fetched';
export const JOIN_CONVERSATION = 'CLIENT/CHAT/JOIN_CONVERSATION';
export const LEAVE_CONVERSATION = 'CLIENT/CHAT/LEAVE_CONVERSATION';
export const LOG_TEST_CHANNEL = 'CLIENT/CHAT/LOG_TEST_CHANNEL';
export const LOG_TEST_NO_CHANNEL = 'CLIENT/CHAT/LOG_TEST_NO_CHANNEL';
export const LOG_TEST_FIX_CHANNEL = 'CLIENT/CHAT/LOG_TEST_FIX_CHANNEL';
export const CREATE_CONVERSATION = 'CLIENT/CHAT/CREATE_CONVERSATION';
export const SEND_END_CONVERSATION = 'CLIENT/CHAT/SEND_END_CONVERSATION';
export const END_CONVERSATION = 'SERVER/CHAT/END_CONVERSATION';
export const CHAT_MESSAGE = 'SERVER/CHAT/CHAT_MESSAGE';
export const CHAT_MESSAGE_FETCHED = 'SERVER/CHAT/CHAT_MESSAGE:fetched';
export const SEND_CHAT_MESSAGE = 'CLIENT/CHAT/SEND_CHAT_MESSAGE';
export const SERVICE_MESSAGE = 'SERVER/CHAT/SERVICE_MESSAGE';
export const SERVICE_MESSAGE_FETCHED = 'SERVER/CHAT/SERVICE_MESSAGE:fetched';
export const SEND_SERVICE_MESSAGE = 'CLIENT/CHAT/SEND_SERVICE_MESSAGE';
export const BECOME_AGENT = 'CLIENT/CHAT/BECOME_AGENT';
export const BECOME_VISITOR = 'CLIENT/CHAT/BECOME_VISITOR';
export const USER_ACTIVE = 'SERVER/CHAT/USER_ACTIVE';
export const USER_INACTIVE = 'SERVER/CHAT/USER_INACTIVE';
export const USER_LIST = 'SERVER/CHAT/USER_LIST';
export const READ_MESSAGE = 'CLIENT/CHAT/READ_MESSAGE';
export const READ_RECEIPT_FETCHED = 'SERVER/CHAT/READ_RECEIPT:fetched';
export const READ_RECEIPT = 'SERVER/CHAT/READ_RECEIPT';
export const TAKE_CONVERSATION = 'CLIENT/CHAT/TAKE_CONVERSATION';
export const CONVERSATION_ASSIGNED = 'SERVER/CHAT/CONVERSATION_ASSIGNED';
export const CONVERSATION_ASSIGNED_FETCHED = 'SERVER/CHAT/CONVERSATION_ASSIGNED:fetched';
export const SEND_TYPING_INFO = 'CLIENT/CHAT/SEND_TYPING_INFO';
export const SEND_BEGIN_TYPING = 'CLIENT/CHAT/SEND_BEGIN_TYPING';
export const SEND_END_TYPING = 'CLIENT/CHAT/SEND_END_TYPING';
export const BEGIN_TYPING = 'SERVER/CHAT/BEGIN_TYPING';
export const END_TYPING = 'SERVER/CHAT/END_TYPING';
export const BEGIN_TYPING_FETCHED = 'BEGIN_TYPING:fetched';
export const END_TYPING_FETCHED = 'END_TYPING:fetched';
export const VISITOR_SESSION = 'SERVER/CHAT/VISITOR_SESSION';
export const VISITOR_INFO = 'SERVER/CHAT/VISITOR_INFO';
export const VISITOR_PATH_UPDATE = 'SERVER/CHAT/VISITOR_PATH_UPDATE';
export const PUSH_VISITOR_PATH = 'CLIENT/CHAT/PUSH_VISITOR_PATH';
export const SET_COMPILE_RESULT = 'CLIENT/CHAT/SET_COMPILE_RESULT';
export const SUBSCRIBE_TO_VISITOR = 'CLIENT/CHAT/SUBSCRIBE_TO_VISITOR';
export const UNSUBSCRIBE_FROM_VISITOR = 'CLIENT/CHAT/UNSUBSCRIBE_FROM_VISITOR';
export const SUBSCRIBE_TO_CONVERSATION = 'CLIENT/CHAT/SUBSCRIBE_TO_CONVERSATION';
export const UNSUBSCRIBE_FROM_CONVERSATION = 'CLIENT/CHAT/UNSUBSCRIBE_FROM_CONVERSATION';
export const SESSION_INACTIVE = 'SERVER/CHAT/SESSION_INACTIVE';
export const ENABLE_WIDGET = 'SERVER/CHAT/ENABLE_WIDGET';
export const DISABLE_WIDGET = 'SERVER/CHAT/DISABLE_WIDGET';
export const END_MY_CONVERSATION = 'END_MY_CONVERSATION';
export const SET_SESSION_METADATA = 'SERVER/SET_SESSION_METADATA';
export const UNSET_SESSION_METADATA = 'SERVER/UNSET_SESSION_METADATA';
export const BECOME_IDLE = 'CLIENT/CHAT/BECOME_IDLE';
export const BECOME_ACTIVE = 'CLIENT/CHAT/BECOME_ACTIVE';
export const AGENT_STATUS = 'SERVER/CHAT/AGENT_STATUS';
export const CREATE_SUBSCRIPTION = 'CLIENT/CREATE_SUBSCRIPTION';
export const AGENT_NOTIFICATIONS = 'SERVER/CHAT/AGENT_NOTIFICATIONS';
export const ACTION_TEST = 'SERVER/ACTION_TEST';