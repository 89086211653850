import {EventEmitter} from "events";
import Postmate from "postmate";
import { runtimeEnv } from "../../../env";

interface VisitorPathEntry {
    title: string,
    path: string
}

type Position = { bottom: number, right: number };

function getPath() {
    return {
        title: document.title,
        path: document.location.href
    }
}

const CLASS = 'logiscool-support-chat-widget';

export class WidgetBridge extends EventEmitter {

    private bridge: any = null as any;

    private buttonPosition = { right: 20, bottom: 60 };
    private widgetPosition = { right: 10, bottom: 50 };
    private lastPath: VisitorPathEntry = { title: '', path: '' };

    private setPath(path: VisitorPathEntry) {
        if(this.lastPath.path === path.path && this.lastPath.title === path.title) return false;
        this.lastPath = path;
        return true
    }

    private update: () => void = () => {};

    constructor(container: HTMLElement|null, token: string, origin = runtimeEnv.REACT_APP_CHAT_URL || window.location.origin) {
        super();

        const handshake = new Postmate({
            container,
            url: `${origin}/widget?access_token=${token}`,
            classListArray: [ CLASS ]
        });

        handshake.then(async (child: any) => {
            this.bridge = child;
            this.emit('ready');

            if(container) {
                const widget = container.firstElementChild as HTMLIFrameElement;

                const showAs = (kind: 'btn'|'open', position: Position) => {
                    widget.className = `${CLASS} ${kind}`;
                    widget.style.right = `${position.right}px`;
                    widget.style.bottom = `${position.bottom}px`
                };
                const hide = () => widget.className = CLASS;

                let buttonVisible = await child.get('buttonVisible');
                let widgetOpen = await child.get('widgetOpen');

                this.update = () => {
                    if (widgetOpen) showAs('open', this.widgetPosition);
                    else if (buttonVisible) showAs('btn', this.buttonPosition);
                    else hide()
                };

                this.update();

                child.on('unread-message', () => {
                    this.emit('unread-message')
                });

                child.on('open-widget', () => {
                    widgetOpen = true;
                    showAs('open', this.widgetPosition);
                    this.emit('widget-open')
                });

                child.on('close-widget', () => {
                    widgetOpen = false;
                    if (buttonVisible) showAs('btn', this.buttonPosition);
                    else hide();
                    this.emit('widget-close')
                });

                child.on('show-button', () => {
                    buttonVisible = true;
                    if (!widgetOpen) showAs('btn', this.buttonPosition)
                });

                child.on('hide-button', () => {
                    buttonVisible = false;
                    if (!widgetOpen) hide()
                })
            }
        })
    }

    widget = {
        onReady: (handler: Function) => this.bridge.on('ready', handler),
        open: () => this.bridge && this.bridge.call('openWidget'),
        close: () => this.bridge && this.bridge.call('closeWidget'),
        setPosition: (position: Position) => {
            this.widgetPosition = position;
            this.update()
        },
        setCompileResult: (result: any) => this.bridge && this.bridge.call('setCompileResult', result)
    };

    button = {
        show: () => this.bridge && this.bridge.call('showButton'),
        hide: () => this.bridge && this.bridge.call('hideButton'),
        setPosition: (position: Position) => {
            this.buttonPosition = position;
            this.update()
        }
    };

    visitor = {
        sendPath: (path?: VisitorPathEntry) => {
            if(this.bridge) {
                if(this.setPath(path || getPath())) {
                    this.bridge.call('sendVisitorPath', { path: this.lastPath })
                }
            }
        }
    }
}