import { Reducer } from 'redux';
import {PRESENT} from "redux-socket-client";
import {CONVERSATION_FETCHED, NO_CONVERSATION} from "../constants";

export interface ReadyState {
    app: number
    widget: boolean
}

export const readyReducer: Reducer = (state = { app: 0, widget: false }, { type }) => {
    switch(type) {
        case PRESENT:
            return { ...state, app: state.app + 1 };

        case CONVERSATION_FETCHED:
        case NO_CONVERSATION:
            return { ...state, widget: true };

        default:
            return state
    }
};