const BrowserBridgeMessageType = {
    Init: 'INIT',
    Ready: 'READY',
    Request: 'REQ',
    Response: 'RESP',
    Emit: 'EMIT'
}

export class NativeBrowserBridge {

    private static readonly instance: NativeBrowserBridge = new NativeBrowserBridge();
    static getInstance() {
        return NativeBrowserBridge.instance
    }

    private constructor() {
    }

    public enableLog = true;
    private log(...messages: any[]) {
        if(this.enableLog) console.log(...messages)
    }
    
    private handlers: { [key: string]: Function[] } = {}
    private waitHandlers: { [key: string]: Function[] } = {}

    private emit(event: string, ...payload: any[]) {
        const handlers = [
            ...(this.handlers[event] || []),
            ...(this.waitHandlers[event] || [])
        ];

        delete this.waitHandlers[event];

        for (let handler of handlers) {
            handler(...payload)
        }
    }

    on(event: string, handler: Function) {
        const handlers = this.handlers[event] || (this.handlers[event] = []);
        handlers.push(handler);
        if(event === 'ready' && this.ready) handler()
    }

    off(event: string, handler: Function) {
        const handlers = this.handlers[event];
        if(handlers) {
            const index = handlers.indexOf(handler);
            if(index !== -1) handlers.splice(index, 1)
        }
    }

    waitFor(event: string) {
        const handlers = this.waitHandlers[event] || (this.waitHandlers[event] = []);
        return new Promise<any>(resolve => handlers.push(resolve))
    }

    origin: string = '*';
    private window: Window = window;
    private ready = false;
    private enabled = false;

    private post(type: string, payload: any) {
        try {
            this.log('[outside] outgoing', type, payload, this.origin);
            this.window.postMessage({type, payload}, this.origin)
        }
        catch(e) {
            this.log('[outside] bridge error', e)
        }
    }

    private process({ data: { type, payload }, origin }: MessageEvent) {
        if(!this.origin || origin !== this.origin) return;
        this.log('[outside] incoming', type, payload, origin, this.origin);

        if(type === BrowserBridgeMessageType.Ready) {
            this.enabled = true;
            this.ready = true;
            this.emit('ready');
            this.log('[outside] ready')
        }
        else if(this.enabled) {
            switch (type) {
                case BrowserBridgeMessageType.Response:
                    this.emit(`response:${payload.type}`, payload.payload)
                    break;

                case BrowserBridgeMessageType.Emit:
                case BrowserBridgeMessageType.Request:
                    this.emit(payload.event || payload.type, payload.payload)
                    break;
            }
        }
    }

    async init(target: Window, origin: string) {
        if(!this.ready) {
            this.window = target;
            this.origin = origin;
            window.addEventListener("message", e => this.process(e), false);
            const isReady = this.waitFor('ready');
            const timer = setInterval(() => this.post(BrowserBridgeMessageType.Ready, {}), 100);
            await isReady;
            clearInterval(timer);
            this.log('[outside] init')
        }
    }

    async request(type: string, payload: any = {}) {
        const resp = this.waitFor(`response:${type}`);
        this.post(BrowserBridgeMessageType.Request, { type, payload })
        return resp
    }

    emitRemote(event: string, payload: any) {
        if(this.ready) {
            this.post(BrowserBridgeMessageType.Emit, {event, payload})
        }
    }

    respond(type: string, payload: any) {
        if(this.ready) {
            this.post(BrowserBridgeMessageType.Response, { type, payload })
        }
    }
}