import io from 'socket.io-client'
import { runtimeEnv } from '../env';

const WSURL = runtimeEnv.REACT_APP_LIVEOPS_URL || 'https://live.dev.scoolcode.com';

export function initSocket(accessToken: string) {
    // NOTE: unfortunately, we have no pingInterval neither pingTimeout at client-side
    // those options are set at server side only
    const socketManager = new io.Manager(WSURL, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 500,	// how long to initially wait before attempting a new reconnection (1000). Affected by +/- randomizationFactor, for example the default initial delay will be between 500 to 1500ms.
        reconnectionDelayMax: 3000, // maximum amount of time to wait between reconnections (5000). Each attempt increases the reconnection delay by 2x along with a randomization as above
        randomizationFactor: 0.5,   // 0 <= randomizationFactor <= 1
        timeout: 5000,     // connection timeout before a connect_error and connect_timeout events are emitted
        autoConnect: true,  // by setting this false, you have to call manager.open whenever you decide it’s appropriate
    });
    const socket = socketManager.socket('/'); // TODO what is this namespace for??
    socket.on('connect', () => {
        socket.emit('authentication', {
            accessToken,
            segments: 'chat'
        })
    })
    return socket;
}