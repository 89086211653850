import React, {Suspense} from 'react';
import {Api} from "./services/Api";
import {initStore} from "./store/store";
import {initSocket} from "./helpers/socket-services";
import {connectToSharedStore} from "redux-socket-client";
import {AuthFlow} from "./components/auth/auth-flow/AuthFlow";
import {StoreState} from "./store/store";
import {userConnected,userDisconnected} from "./store/local/actions";
import {AnyAction, Store} from "redux";
import { Provider } from 'react-redux'
import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom'
import { Route } from 'react-router'
import './App.scss';
import './balloon.css';
import {LoadingPage} from "./pages/loading/LoadingPage";
import {getUrlParameter} from "./helpers/selectors";
import TestPageNative from "./pages/test/TestPageNative";

let store: Store<StoreState, AnyAction> | null = null;

const ChatApp = React.lazy(() => import('./pages/chat-app/ChatApp'));
const ChatWidget = React.lazy(() => import('./pages/chat-widget/ChatWidgetContainer'));
const TestPage = React.lazy(() => import('./pages/test/TestPage'));

const access_token = getUrlParameter('access_token');

const App: React.FC = () => {
  const [authState, setAuthState] = React.useState({
    isAuth: true
  });

  const onAuthSuccess = async (token: string, from?: string) => {
    try {
      Api.init(token);
      store = await initStore();

      if(from) window.history.replaceState({}, '', from);

      setAuthState({
        isAuth: false
      });

      const socket = initSocket(token);
      socket.on('connect', () => { if (store) store.dispatch(userConnected()); });
      socket.on('reconnect', () => {console.log('socket reconnected!'); if (store) store.dispatch(userConnected());});
      socket.on('connect_error', (err:any) => {console.log('socket connect_error '+err); if (store) store.dispatch(userDisconnected()); });
      socket.on('connect_timeout', (err:any) => {console.log('socket connect_timeout '+err); if (store) store.dispatch(userDisconnected()); });
      socket.on('error', (err:any) => {console.log('socket error '+err); if (store) store.dispatch(userDisconnected()); });
      socket.on('reconnect_error', (err:any) => {console.log('socket reconnect_error '+err); if (store) store.dispatch(userDisconnected()); });
      socket.on('reconnect_failed', (err:any) => {console.log('socket reconnect_failed '+err); if (store) store.dispatch(userDisconnected()); });
      socket.on('disconnect', () => {console.log('socket disconnect'); if (store) store.dispatch(userDisconnected()); });
      store.dispatch(connectToSharedStore(socket));

    } catch (e) {
      setAuthState({
        isAuth: false
      })
    }
  };

  if (authState.isAuth) {
    if(access_token) {
      onAuthSuccess(access_token).then(() => {});
      return <LoadingPage />
    }

    return (
      <AuthFlow onSuccess={onAuthSuccess} />
    )
  }

  if(store === null) {
    console.error('no store');
    return (
        <span>Something went wrong.</span>
    )
  }

  return (
      <Provider store={store}>
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <Switch>
                <Route path="/agent/:subpage?" component={ChatApp} />
                <Route path="/widget" component={ChatWidget} />
                <Route path="/test" component={TestPage} />
                <Route path="/test_native" component={TestPageNative} />
                <Route path="/" exact render={() => store && store.getState().grants.find(g => g.product === 'edup.support-chat' && g.feature === 'agent')
                    ? <Redirect to={{ pathname: '/agent' }} />
                    : <Redirect to={{ pathname: '/test' }} />
                } />
              </Switch>
            </Suspense>
          </Router>
      </Provider>
  );
}

export default App;
