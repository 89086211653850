import {client} from "redux-socket-client";
import {
    BECOME_AGENT,
    JOIN_CONVERSATION,
    SEND_CHAT_MESSAGE,
    SEND_SERVICE_MESSAGE,
    CREATE_CONVERSATION,
    LEAVE_CONVERSATION,
    BECOME_VISITOR,
    SEND_END_CONVERSATION,
    READ_MESSAGE,
    SEND_BEGIN_TYPING,
    SEND_END_TYPING,
    TAKE_CONVERSATION,
    LOAD_HISTORY,
    SUBSCRIBE_TO_VISITOR,
    UNSUBSCRIBE_FROM_VISITOR,
    SUBSCRIBE_TO_CONVERSATION,
    UNSUBSCRIBE_FROM_CONVERSATION,
    PUSH_VISITOR_PATH,
    END_MY_CONVERSATION,
    USER_ACTIVE,
    USER_INACTIVE,
    SESSION_INACTIVE,
    SET_SESSION_METADATA,
    BECOME_ACTIVE,
    BECOME_IDLE, CREATE_SUBSCRIPTION, LOG_TEST_NO_CHANNEL, LOG_TEST_CHANNEL, LOG_TEST_FIX_CHANNEL, SET_COMPILE_RESULT
} from "../constants";
import {VisitorPathEntry, User} from "../../../types";
const uuid = require('uuid').v4;

export const sendChatMessage = client((conversation: string, message: string) => ({
    type: SEND_CHAT_MESSAGE,
    payload: { conversation, message, cacheId: uuid() }
}));

export const sendServiceMessage = client((conversation: string, kind: string, data: any) => ({
    type: SEND_SERVICE_MESSAGE,
    payload: { conversation, kind, data, cacheId: uuid() }
}));

export const sendBeginTyping = client((conversation: string, department: string) => ({
    type: SEND_BEGIN_TYPING,
    payload: { conversation, department }
}));

export const sendEndTyping = client((conversation: string, department: string) => ({
    type: SEND_END_TYPING,
    payload: { conversation, department }
}));

export const becomeAgent = client(() => ({
    type: BECOME_AGENT
}));

export const subscribeToNotifications = client((subscriptionObject: any, channel: string = 'support_chat') => ({
    type: CREATE_SUBSCRIPTION,
    payload: { provider: 'webpush', channel, subscriptionObject },
    subscribe: true
}));

export const toggleAgentNotifications = client((enabled: boolean) => ({
    type: 'CLIENT/CHAT/TOGGLE_AGENT_NOTIFICATIONS',
    payload: { enabled },
    subscribe: true
}));

export const requestAgentNotifications = client(() => ({
    type: 'CLIENT/CHAT/REQUEST_AGENT_NOTIFICATIONS',
    payload: { },
    subscribe: true
}));

export const becomeVisitor = client((visitorInfo: { visitorPath: VisitorPathEntry[], userAgent: string }) => ({
    type: BECOME_VISITOR,
    payload: visitorInfo
}));

export const subscribeToVisitor = client((visitor: string) => ({
    type: SUBSCRIBE_TO_VISITOR,
    payload: { visitor }
}));

export const unsubscribeFromVisitor = client((visitor: string) => ({
    type: UNSUBSCRIBE_FROM_VISITOR,
    payload: { visitor }
}));

export const subscribeToConversation = client((conversation: string) => ({
    type: SUBSCRIBE_TO_CONVERSATION,
    payload: { conversation }
}));

export const unsubscribeFromConversation = client((conversation: string) => ({
    type: UNSUBSCRIBE_FROM_CONVERSATION,
    payload: { conversation }
}));

export const pushVisitorPath = client((path: VisitorPathEntry) => ({
    type: PUSH_VISITOR_PATH,
    payload: { path }
}));

export const setCompileResult = client((result: any) => ({
    type: SET_COMPILE_RESULT,
    payload: { compileResult: result }
}));

export const joinConversation = client((conversation: string, asVisitor: boolean = false) => ({
    type: JOIN_CONVERSATION,
    payload: { conversation, visitor: asVisitor }
}));

export const takeConversation = client((conversation: string) => ({
    type: TAKE_CONVERSATION,
    payload: { conversation }
}));

export const leaveConversation = client((conversation: string) => ({
    type: LEAVE_CONVERSATION,
    payload: { conversation }
}));

export const sendTestWithoutChannel = client((conversation: string) => ({
    type: LOG_TEST_NO_CHANNEL,
    payload: { conversation }
}))

export const sendTestFixChannel = client((conversation: string) => ({
    type: LOG_TEST_FIX_CHANNEL,
    payload: { conversation }
}))

export const sendTestWithChannel = client((conversation: string) => ({
    type: LOG_TEST_CHANNEL,
    payload: { conversation }
}))

export const sendEndConversation = client((conversation: string, resolution?: string) => ({
    type: SEND_END_CONVERSATION,
    payload: { conversation, resolution }
}));

export const endMyConversation = (conversation: string, resolution?: string) => ({
    type: END_MY_CONVERSATION,
    payload: { conversation, resolution }
});

export const createConversation = client((visitor: string) => ({
    type: CREATE_CONVERSATION,
    payload: { visitor }
}));

export const readMessages = client((conversation: string, message: string) => ({
    type: READ_MESSAGE,
    payload: { conversation, message }
}));

export const loadHistory = client((page: number, limit: number = 10) => ({
    type: LOAD_HISTORY,
    payload: { page, limit }
}));

export const userActive = (user: User, kind: string) => ({
    type: USER_ACTIVE,
    payload: { user, kind }
});

export const userInactive = (user: string) => ({
    type: USER_INACTIVE,
    payload: { user }
});

export const sessionInactive = (visitor: string, session: string) => ({
    type: SESSION_INACTIVE,
    payload: { visitor, session }
});

export const setSessionMetadata = client((key: string, value:string) => ({
    type: SET_SESSION_METADATA,
    payload: { key, value }
}));

export const becomeActive = client(() => ({
    type: BECOME_ACTIVE
}));

export const becomeIdle = client(() => ({
    type: BECOME_IDLE
}));