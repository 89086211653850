import * as React from 'react';
import { MdWork } from 'react-icons/md';
import {Button} from "..";
import {FetchedConversation} from "../../types";
import {takeConversation} from "../../store/liveops/actions";
import "./ServeNextFAB.scss";
import {StoreState} from "../../store/store";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {playSoundEffect} from "../../helpers/sound-service";

interface StateProps {
    conversations: FetchedConversation[]
}

interface DispatchProps {
    takeConversation: typeof takeConversation;
}

type AllProps = StateProps & DispatchProps

const ServeNextFAB: React.FC<AllProps> = ({ conversations, takeConversation }) => {
    const nextConversation = conversations
        .filter(c => !c.agent)
        .sort((a,b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime())[0];
    const nextConversationId = nextConversation ? nextConversation.id : false;

    React.useEffect(() => {
         if(nextConversationId) playSoundEffect('new-chat', 2000)
    }, [ nextConversationId ]);

    return (
        <div className={`serve-fab ${nextConversation ? 'visible' : 'hidden'}`}>
            {nextConversation && <Link to={`/agent/chat/${nextConversation.id}`}>
                <Button round className="large primary blink" icon={MdWork}
                        onClick={() => takeConversation(nextConversation.id)}/>
            </Link>}
        </div>
    )
};

const mapStateToProps = (state: StoreState) => {
    return {
        conversations: state.conversations
    }
};

const mapDispatchToProps = {
    takeConversation
};

const ConnectedServeNextFAB = connect<
    StateProps,
    DispatchProps,
    {},
    StoreState
    >(
    mapStateToProps,
    mapDispatchToProps
)(ServeNextFAB);

export { ConnectedServeNextFAB as ServeNextFAB }