import * as React from 'react'
import { Reducer, useReducer, useEffect } from 'react'
import qs from 'qs'
import { Api } from '../../../services/Api'

interface Props {
    render: (state: { isLoading: boolean; hasError: boolean }) => JSX.Element
    onSuccess?: (token: string, origin: string) => void
}

export const OauthReceiver: React.FC<Props> = props => {
    const [tokenState, dispatch] = useReducer(tokenReducer, {
        isLoading: true,
        hasError: false
    });

    const { onSuccess } = props;
    useEffect(() => {
        const getToken = async (authCode: string) => {
            return await Api.getToken(authCode)
        };

        const queryString = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });

        getToken(queryString.code)
            .then(token => {
                if (onSuccess) {
                    try {
                        let origin = '/';
                        try {
                            origin = JSON.parse(queryString.state).origin
                        } catch (e) {}
                        onSuccess(token, origin);
                        dispatch({ type: 'FETCH_SUCCESS' })
                    } catch (e) {
                        console.error(e);
                        dispatch({ type: 'FETCH_ERROR' })
                    }
                }
            })
            .catch(e => {
                console.error(e);
                dispatch({ type: 'FETCH_ERROR' })
            })
    }, [onSuccess]);

    return props.render({
        isLoading: tokenState.isLoading,
        hasError: tokenState.hasError
    })
};

interface TokenState {
    isLoading: boolean
    hasError: boolean
}

type TokenAction = { type: 'FETCH_SUCCESS' } | { type: 'FETCH_ERROR' }

const tokenReducer: Reducer<TokenState, TokenAction> = (state, action) => {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false
            };
        case 'FETCH_ERROR':
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            return state
    }
};
