import * as React from 'react'
import { Api } from '../../../services/Api'

interface Props {
    render: (url: string) => JSX.Element
    redirectUri: string
    origin: string
}

export const OauthSender: React.FC<Props> = props => {
    const url = Api.createOauthUrl(props.origin);
    return props.render(url)
};
