import { Reducer } from 'redux';
import {OPEN_WIDGET, CLOSE_WIDGET, SHOW_BUTTON, HIDE_BUTTON} from "../constants";
import {CREATE_CONVERSATION, DISABLE_WIDGET, ENABLE_WIDGET, END_CONVERSATION, SET_COMPILE_RESULT} from "../../liveops";
import {OperatingHoursEntry} from "../../../types";
import { DateTime } from 'luxon';

export interface WidgetState {
    open: boolean
    ended: boolean
    available: boolean
    autoCreateConversation: boolean
    operatingHours?: OperatingHoursEntry[]
    button: {
        visible: boolean
    }
    compileResult?: any
}

const DEFAULT_STATE = {
    open: false,
    ended: false,
    available: false,
    autoCreateConversation: false,
    button: {
        visible: false
    },
    compileResult: []
};

const days:('Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'|'workdays'|'weekend'|'everyday')[] = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
function localizeHours(zone: string, hours: OperatingHoursEntry[]) {
    const h = [];
    for (const entry of hours) {
        const dtDeptFrom = DateTime.fromObject({day:15, hour: entry.fromHour, minute: entry.fromMinute, zone});
        const dtFrom = dtDeptFrom.toLocal();
        // handle time over/underflow
        const dayDiff = dtFrom.get("day") - dtDeptFrom.get("day");
        let { day, closed } = entry;
        if (dayDiff !== 0)
        {
            const dayIndex = days.indexOf(day);
            if (dayIndex >= 0)
                day = days[(dayIndex + dayDiff) % 7];
            else
                console.warn('day not found: ', day);
        }
        const dtTo = DateTime.fromObject({day:15, hour: entry.toHour, minute: entry.toMinute, zone}).toLocal();
        h.push({
            day,
            closed, 
            fromHour: dtFrom.get('hour'),
            fromMinute: dtFrom.get('minute'),
            toHour: dtTo.get('hour'),
            toMinute: dtTo.get('minute'),
        })
        //console.log(day, dtFrom.toFormat('HH:mm'), '->', dtTo.toFormat('HH:mm'));
    }
    return h;
}

export const widgetReducer: Reducer = (state = DEFAULT_STATE, { type, payload }) => {
    switch(type) {
        case ENABLE_WIDGET:
            return { ...state, available: true, operatingHours: localizeHours(payload.timeZone, payload.operatingHours) };

        case DISABLE_WIDGET:
            return { ...state, available: false, operatingHours: localizeHours(payload.timeZone, payload.operatingHours) };

        case OPEN_WIDGET:
            return { ...state, open: true };

        case CLOSE_WIDGET:
            return { ...state, open: false, ended: false };

        case SHOW_BUTTON:
            return { ...state, button: { ...state.button, visible: true } };

        case HIDE_BUTTON:
            return { ...state, button: { ...state.button, visible: false } };

        case END_CONVERSATION:
            if(!state.open) return state;
            return { ...state, ended: true };

        case CREATE_CONVERSATION:
            return { ...state, ended: false };

        case SET_COMPILE_RESULT:
            return { ...state, compileResult: payload.compileResult };

        default:
            return state
    }
};