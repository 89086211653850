import * as React from 'react';
import { IconType } from 'react-icons';
import { MdPhoneIphone as MdiPhone, MdPhoneAndroid as MdAndroid, MdTabletMac as MdiPad } from 'react-icons/md';
import {
    FaChrome, FaFirefox, FaSafari, FaEdge, FaInternetExplorer, FaOpera, FaGlobe,
    FaApple as FaMac, FaAndroid, FaApple as FaiOS, FaWindows, FaLinux, FaDesktop
} from 'react-icons/fa';
import {NameVersion, SessionInfo} from "../../../types";

const browserIcons: Record<string, IconType> = { FaChrome, FaFirefox, FaSafari, FaEdge, FaInternetExplorer, FaOpera };
const OSIcons: Record<string, IconType> = { FaMac, FaWindows, FaLinux, FaAndroid, FaiOS };
const DeviceIcons: Record<string, IconType> = { MdiPhone, MdAndroid, MdiPad };
const IconProvider: Record<'browser'|'OS'|'device', (name: string) => IconType> = {
    browser: name => browserIcons[`Fa${name.replace('Mobile', '').replace(/ /g, '')}`] || FaGlobe,
    OS: name => OSIcons[`Fa${name.replace('OS X', '').replace(/ /g, '')}`] || FaGlobe,
    device: name => DeviceIcons[`Md${name}`] || FaDesktop
};

function infoText(kind: string, { name, version }: NameVersion) {
    if(kind === 'device') return name === 'Other' ? 'Desktop' : name;
    return `${name} ${version}`
}

interface Props {
    kind: 'browser'|'OS'|'device',
    entry: SessionInfo
}

export const UserAgentIcon: React.FC<Props> = ({ kind, entry }) => {
    const info = entry[kind];
    if (!info || !info.name || !IconProvider[kind](info.name))
        return <span />;
    const Icon = IconProvider[kind](info.name);
    return (
        <span className="user-agent-icon"
              aria-label={infoText(kind, info)}
              data-balloon-pos="up">
            <Icon />
        </span>
    )
};