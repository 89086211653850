import * as React from 'react'
import "./LoadingPage.scss";
import {Loader} from "../../components";

export const LoadingPage: React.FC = () => {
    return (
        <div className="loading-page">
            <Loader />
        </div>
    )
};