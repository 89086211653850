import * as React from 'react';
import "./Button.scss";
import {CSSProperties} from "react";

interface Props {
    icon?: any
    label?: any
    onClick?: () => void
    round?: boolean
    disabled?: boolean
    className?: string
    style?: CSSProperties
}

export const Button: React.FC<Props> = props => {
    const { icon: Icon, label, onClick, round, disabled, className = '', style } = props;
    const hasHover = !!onClick;
    return (
        <button style={style} disabled={disabled} className={`button ${round ? 'round' : ''} ${hasHover ? '' : 'disabled'} ${className}`} onClick={onClick}>
            {Icon && <span className="button-icon"><Icon /></span>}
            {!round && <span className="button-label desktop-only">{label}</span>}
        </button>
    )
};