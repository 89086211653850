import { Reducer } from 'redux';
import {PRESENT} from "redux-socket-client";
import {
    BEGIN_TYPING_FETCHED,
    CHAT_MESSAGE_FETCHED,
    CONVERSATION_FETCHED,
    END_CONVERSATION,
    READ_RECEIPT_FETCHED,
    SERVICE_MESSAGE_FETCHED,
    END_TYPING, CONVERSATION_ASSIGNED_FETCHED, JOIN_CONVERSATION, LEAVE_CONVERSATION, ACTION_TEST
} from "../constants";
import {FetchedConversation} from "../../../types";

function updateConversationInPlace(state: FetchedConversation[], payload: any, createDelta: (conversation: FetchedConversation) => any) {
    const index = state.findIndex(c => c.id === payload.conversation);
    if (index === -1) return state;
    const newState = [...state];
    newState[index] = {
        ...newState[index],
        ...createDelta(newState[index])
    };
    return newState
}

export const conversationsReducer: Reducer = (state: FetchedConversation[] = [], { type, payload = {} }) => {
    switch(type) {
        case ACTION_TEST:
            console.log(`ACTION_TEST: ${JSON.stringify(payload)}`);
            return state;
        case CONVERSATION_FETCHED:
            if(state.find(c => c.id === payload.conversation.id)) return state;
            return [ ...(state || []), payload.conversation ];
        case CHAT_MESSAGE_FETCHED:
        case SERVICE_MESSAGE_FETCHED:
            let conversation = state.find(c => c.id === payload.conversation);
            if(conversation) {
                if(payload.delivered && payload.cacheId) {
                    const index = conversation.messages.findIndex(m => m.cacheId === payload.cacheId);
                    if(index !== -1) {
                        const newMessages = [ ...conversation.messages ];
                        newMessages[index] = payload;
                        return [
                            {
                                ...conversation,
                                messages: newMessages
                            },
                            ...state.filter(c => c.id !== payload.conversation)
                        ]
                    }
                }

                return [
                    {
                        ...conversation,
                        messages: [
                            ...conversation.messages,
                            payload
                        ]
                    },
                    ...state.filter(c => c.id !== payload.conversation)
                ]
            }
            else {
                return state
            }
        case CONVERSATION_ASSIGNED_FETCHED:
            return updateConversationInPlace(state, payload, () => ({
                agent: payload.agent
            }));
        case READ_RECEIPT_FETCHED:
            return updateConversationInPlace(state, payload, conversation => ({
                readReceipts: [
                    ...conversation.readReceipts,
                    payload
                ]
            }));
        case BEGIN_TYPING_FETCHED:
            return updateConversationInPlace(state, payload, conversation => ({
                typings: [
                    ...conversation.typings,
                    payload.user
                ]
            }));
        case END_TYPING:
            return updateConversationInPlace(state, payload, conversation => ({
                typings: conversation.typings.filter(user => user.id !== payload.user)
            }));
        case JOIN_CONVERSATION:
            return updateConversationInPlace(state, payload, () => ({
                joined: true
            }));
        case LEAVE_CONVERSATION:
            return updateConversationInPlace(state, payload, () => ({
                joined: false
            }));
        case END_CONVERSATION:
            return state.filter(c => c.id !== payload.conversation);
        case PRESENT:
            return [];
        default:
            return state
    }
};