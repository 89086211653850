import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Api } from './Api'

export async function initLocalisation(lng: string) {
    const en = await Api.getLocalisationFile('en-US')
    const hu = await Api.getLocalisationFile('hu-HU')

    if (en && hu) {
        i18n
            .use(initReactI18next)
            .init({
                load: 'currentOnly',
                resources: {
                    'hu-HU': {
                        translation: hu.data
                    },
                    'en-US': {
                        translation: en.data
                    }
                },
                lng,
                fallbackLng: 'en-US',
                interpolation: {
                    escapeValue: true
                },
                react: {
                    wait: true
                }
            })
    }
}
