import axios, { AxiosInstance } from 'axios'
import {MinimalUser, User, UserSetting, IGrant} from '../types'
import Axios from "axios";
import { runtimeEnv } from '../env';

const userCache: Record<string, MinimalUser> = {};
const CHAT_LOCALISATION_URL = `${runtimeEnv.REACT_APP_API_URL}/language-files/support-chat`

class Api {
    static axiosInstance: AxiosInstance;
    static apiUrl = runtimeEnv.REACT_APP_API_URL;

    static token: string = '';

    static init(token: string) {
        this.token = token;
        this.axiosInstance = axios.create({
            baseURL: Api.apiUrl,
            params: {
                access_token: token
            }
        });

        this.axiosInstance.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (
                    error.response &&
                    error.response.status === 403 &&
                    error.response.data === 'Invalid Token'
                ) {
                    const origin = encodeURIComponent(
                        window.location.pathname +
                            window.location.search
                    );
                    window.location.assign(
                        this.createOauthUrl(origin)
                    )
                }
                return Promise.reject(error)
            }
        )
    }

    static cert = {
        grant_type: 'authorization_code',
        client_id: 'support-chat',
        client_secret:
            'e8yJWeDQ6BbKkFNjjkYxzg6VbfvGjuegmUh9kdueQd8meGm1H1s0ORRYpN9L',
        redirect_uri: window.location.origin + '/auth/scoolcode'
    };

    static async getToken(authCode: string) {
        const res = await axios.post<{ access_token: string }>(
            `${runtimeEnv.REACT_APP_OAUTH_URL}/api/oauth/token`,
            {...Api.cert, code: authCode }
        );
        return res.data.access_token
    }

    static createOauthUrl(origin: string) {
        const url = `${
            runtimeEnv.REACT_APP_OAUTH_URL
        }/api/oauth/authorize?response_type=code&client_id=${Api.cert.client_id}&redirect_uri=${
            window.location.origin
        }/auth/scoolcode&state=${JSON.stringify({
            origin: origin
        })}`;

        return url
    }

    static logout() {
        window.location.href = `${runtimeEnv.REACT_APP_OAUTH_URL}/api/auth/logout?redirect=${
            window.location.origin
        }`
    }

    static async getMe() {
        const [user, locale] = await Promise.all([
            Api.axiosInstance.get<User>('accounts/me', { params: { access_token: Api.token } }),
            Api.getLocale()
        ]);
        return {
            ...user.data,
            locale: locale.locale
        }
    }

    static async getLocale() {
        const res = await Api.axiosInstance.get<{locale: string}>('accounts/me/locale', { params: { access_token: Api.token } })
        return res.data
    }

    static async getMySetting(key: string) {
        const res = await Api.axiosInstance.get<UserSetting>(`accounts/me/settings/${key}`, { params: { access_token: Api.token } })
        return res.data
    }

    static async getUser(id: string) {
        return userCache[id] || (
            userCache[id] = (await Api.axiosInstance.get<MinimalUser>(`accounts/${id}`)).data
        )
    }

    static async getLocalisationFile(locale: string) {
        const res = await Axios.get( `${CHAT_LOCALISATION_URL}/${locale}.json`)
        return res.data
    }

    static async getGrants() {
        const grants: IGrant[] = [
            { product: 'edup.support-chat', feature: 'visitor' },
            { product: 'edup.support-chat', feature: 'agent' },
            { product: 'edup.support-chat', feature: 'superagent' }
        ];
        const res = await Axios.post<{ grants: IGrant[] }>(
            `${Api.apiUrl}/accounts/me/check-grants?access_token=${this.token}`,
            { grants }
        )
        return res.data.grants
    }

    static async getMySubscriptions() {
        const res = await Api.axiosInstance.get<{ channel: string }[]>('/subscriptions/my', {
            params: { fields: 'channel' },
        })
        return res.data
    }

}

export { Api }
