import * as React from 'react';
import {FetchedChatMessage, FetchedConversation, MinimalUser} from "../../types";
import "./MessageList.scss";
import moment from "moment";
import {asFriendlyTime} from "../../helpers/selectors";
import { ServiceMessage } from "./ServiceMessage";
import hljs from 'highlight.js';
import './highlightjs.css';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);

interface Props {
    conversation: FetchedConversation
    user: MinimalUser
    readMessages?: (conversation: string, message: string) => void
    manualScroll?: boolean
}

function getReaders(conversation: FetchedConversation, user: MinimalUser) {
    const filteredMessages = conversation.messages.filter(m => !m.kind);
    const lastMsg = filteredMessages[filteredMessages.length - 1];
    if(!lastMsg) return [];
    return conversation.readReceipts
        .filter(rr => rr.message === lastMsg.id && rr.user.id !== user.id && rr.user.id !== lastMsg.from.id)
        .map(rr => rr.user)
}

let codeBlocksCount = 0;

export const MessageList: React.FC<Props> = props => {
    const [ showImage, setShowImage ] = React.useState('');

    const formatText = (text: string | undefined) => {
        if(!text) return '';
        const parts = text.split('```');
        let value = [];
        const trimLineBreaks = (text: string) => {
            while (text.startsWith('\n')) {
                text = text.substring(1);
            }
            while (text.endsWith('\n')) {
                text = text.substring(0, text.length - 1);
            }
            return text;
        }
        for (let i = 0; i < parts.length; i++) {
            if (i % 2 === 0) {
                // Explanation part
                const text = trimLineBreaks(parts[i]);
                const textParts = text.split('`');
                for (let j = 0; j < textParts.length; j++) {
                    if (j % 2 === 0) {
                        // Plain text
                        value.push(textParts[j]);
                    }
                    else {
                        // Code snippet inside text
                        value.push(<code id={'to-be-highlighted-' + codeBlocksCount}>{textParts[j]}</code>);
                        codeBlocksCount++;
                    }
                }
            }
            else {
                // Code part
                let code = parts[i];
                // Remove the first line if it's a language header
                const lower = code.toLowerCase();
                const headers = ['python\n', 'javascript\n', 'stagescript\n', 'stagepython\n',
                    'python', 'javascript', 'stagescript', 'stagepython'];
                for (let language of headers) {
                    if (lower.startsWith(language)) {
                        code = parts[i].substring(language.length);
                        break;
                    }
                }
                code = trimLineBreaks(code);
                // Store the remaining code
                value.push(<pre><code id={'to-be-highlighted-' + codeBlocksCount}>{code}</code></pre>);
                codeBlocksCount++;
            }
        } 
        return value;
    }

    function renderMessage(entry: FetchedChatMessage, self: MinimalUser, next?: FetchedChatMessage, prev?: FetchedChatMessage) {
        if(entry.kind && entry.kind !== 'image') {
            switch(entry.kind) {
                case 'join-conversation':
                    if(self.id === entry.data.client.id) return <></>;
                    return <ServiceMessage>{entry.data.client.username} joined the chat</ServiceMessage>;
                case 'take-conversation':
                    if(self.id === entry.data.client.id) return <></>;
                    return <ServiceMessage>{entry.data.client.username} will help you</ServiceMessage>;
                case 'leave-conversation':
                    return <ServiceMessage>{entry.data.client.username} left the chat</ServiceMessage>;
                case 'end-conversation':
                    return <ServiceMessage>The agent ended this chat.</ServiceMessage>;
                default:
                    return <ServiceMessage>Unknown service message: {entry.kind}</ServiceMessage>;
            }
        }
        else {
            const className = [ 'chat-bubble-outer' ];
            const incoming = entry.from.id !== self.id;
            const hasUsername = incoming && (!prev || !prev.from || prev.from.id !== entry.from.id);
            const timeDiffNext = next ? moment(next.createdAt).diff(entry.createdAt, 'm') : 0;

            if(incoming) className.push('incoming');
            if(entry.delivered) className.push('delivered');
            if(!next || (next.kind || next.from.id !== entry.from.id) || timeDiffNext >= 1) className.push('last');
            if(entry.kind === 'image') className.push('image-type');
            const message = (
                <div key="message" className={className.join(' ')}>
                    {hasUsername && <div className="username">
                        {entry.from.username}
                    </div>}
                    <div className="chat-bubble">
                        <span>
                            {incoming ? formatText(entry.message) : entry.message}
                        </span>
                        {entry.kind === 'image' && (
                            <img
                                onClick={() => setShowImage(entry.data.data)}
                                src={entry.data.data}
                                style={{maxWidth:"100%"}} alt=""
                            />
                        )}
                    </div>
                </div>
            );

            const timeDiffPrev = prev ? moment(entry.createdAt).diff(prev.createdAt, 'm') : 0;
            if(timeDiffPrev >= 4) {
                return [
                    <div key="timestamp" className="timestamp">{asFriendlyTime(entry.createdAt)}</div>,
                    message
                ]
            }
            else if(timeDiffPrev >= 1) {
                return [
                    <div key="timestamp" className="timestamp invisible" />,
                    message
                ]
            }

            return message
        }
    }

    const messagesEndRef = React.useRef(null);
    const [ currentConversationId, setCurrentConversationId ] = React.useState('');
    const [ lastMsgId, setLastMsgId ] = React.useState('');
    const [ timer, setTimer ] = React.useState<NodeJS.Timeout|null>(null);
    const sendReadReceipt = () => {
        if(props.readMessages) {
            const filteredMessages = props.conversation.messages.filter(m => !m.kind);
            const lastMsg = filteredMessages[filteredMessages.length - 1];
            if (lastMsg && lastMsg.id !== lastMsgId) {
                setLastMsgId(lastMsg.id);
                props.readMessages(props.conversation.id, lastMsg.id)
            }
        }
    };
    const scrollToBottom = () => {
        const firstTime = currentConversationId !== props.conversation.id;
        (messagesEndRef.current as any).scrollIntoView({behavior: firstTime ? "auto" : "smooth"});
        if(timer) {
            clearTimeout(timer);
            setTimer(null)
        }
    };
    React.useEffect(() => {
        if(props.manualScroll) return;
        scrollToBottom();
        if(timer) clearTimeout(timer);
        setTimer(setTimeout(sendReadReceipt, 250))
        // eslint-disable-next-line
    }, [props.conversation, props.manualScroll]);
    React.useEffect(() => setCurrentConversationId(props.conversation.id), [props.conversation.id]);
    React.useEffect(() => {
        for (let i = 0; i < codeBlocksCount; i++)
        {
            const codeElement = document.getElementById('to-be-highlighted-' + i);
            if (codeElement && !codeElement.innerHTML.includes('<span class="hljs')) {
                hljs.highlightBlock(codeElement as HTMLElement);
            }
        }
    }, [props.conversation])

    codeBlocksCount = 0;
    return (
        <div className="message-list">
            {props.conversation.messages.map((entry, i) => <React.Fragment key={entry.id || i}>
                {renderMessage(entry, props.user, props.conversation.messages[i+1], props.conversation.messages[i-1])}
            </React.Fragment>)}
            <div>
                <div className="conversation-footer">
                    <div className="read-receipts">
                        {getReaders(props.conversation, props.user).map(user => (
                            <span key={user.id} className="reader-username">{user.username}</span>
                        ))}
                    </div>
                    <div className="typings">
                        {props.conversation.typings.map(user => (
                            <span key={user.id} className="typing-username">{user.username}</span>
                        ))}
                    </div>
                </div>
            </div>
            <div ref={messagesEndRef} />

            { !!showImage && (
                <div onClick={() => setShowImage('')} className="image-overlay">
                    <img src={showImage} alt=""/>
                </div>
            )}
        </div>
    )
};
