import { Reducer } from 'redux';
import {PRESENT} from "redux-socket-client";
import {
    SESSION_INACTIVE,
    SUBSCRIBE_TO_VISITOR,
    UNSUBSCRIBE_FROM_VISITOR, USER_INACTIVE, VISITOR_INFO,
    VISITOR_PATH_UPDATE,
    VISITOR_SESSION
} from "../constants";
import {VisitorInfo, VisitorSession} from "../../../types";

export interface VisitorSessionsState {
    visitor?: string
    info?: VisitorInfo
    sessions: VisitorSession[]
}

export const sessionsReducer: Reducer = (state: VisitorSessionsState = { sessions: [] }, { type, payload = {} }) => {
    switch(type) {
        case VISITOR_SESSION:
            if(payload.visitor !== state.visitor) return state;
            if(state.sessions.find(s => s.session === payload.session)) return state;
            return { ...state, sessions: [ payload, ...state.sessions ] };

        case VISITOR_INFO:
            if(payload.visitor !== state.visitor) return state;
            return { ...state, info: payload.visitorInfo };

        case SUBSCRIBE_TO_VISITOR:
            return { visitor: payload.visitor, sessions: [] };

        case UNSUBSCRIBE_FROM_VISITOR:
            return { sessions: [] };

        case VISITOR_PATH_UPDATE:
            if(payload.visitor !== state.visitor) return state;
            const sessions = [ ...state.sessions ];
            const index = sessions.findIndex(({session}) => session === payload.session);
            const session = sessions[index];
            sessions[index] = {
                session: payload.session,
                visitorInfo: {
                    ...session.visitorInfo,
                    visitorPath: [ payload.path, ...session.visitorInfo.visitorPath ]
                }
            };
            return { ...state, sessions };

        case SESSION_INACTIVE:
            if(payload.visitor !== state.visitor) return state;
            return { ...state, sessions: state.sessions.filter(s => s.session !== payload.session) };

        case USER_INACTIVE:
            if(payload.user !== state.visitor) return state;
            return { ...state, sessions: [] };

        case PRESENT:
        default:
            return state
    }
};