import {Howl} from 'howler';

const delayed: Record<string, boolean> = {};

function delay(name: string, by: number) {
    delayed[name] = true;
    setTimeout(() => delayed[name] = false, by)
}

export function playSoundEffect(name: string, delayBeforeNext: number = 0, delayId: string = '') {
    if(delayed[delayId || name]) return;
    new Howl({ src: [ `${window.location.origin}/sounds/${name}.mp3` ] }).play();
    if(delayBeforeNext) delay(delayId || name, delayBeforeNext)
}
