import {CLOSE_WIDGET, HIDE_BUTTON, OPEN_WIDGET, SHOW_BUTTON, USER_CONNECTED, USER_DISCONNECTED} from "../constants";

export const openWidget = () => ({
    type: OPEN_WIDGET
});

export const closeWidget = () => ({
    type: CLOSE_WIDGET
});

export const showButton = () => ({
    type: SHOW_BUTTON
});

export const hideButton = () => ({
    type: HIDE_BUTTON
});

export const userConnected = () => ({
    type: USER_CONNECTED
});

export const userDisconnected = () => ({
    type: USER_DISCONNECTED
});
